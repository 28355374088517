import { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import banner from "../../assets/banner_Marcas.jpg"
import banner2 from "../../assets/services2.png"
import Footer from "../../components/footer/Footer"
import Header from "../../components/header/Header"
import EmailService from "../../services/EmailService"
import ScrollToTopButton from "../home/components/ScrollToTopButton"
export default function ServicesScreen() {
    useEffect(() => {
        document.title = "Logplace - Nossos Serviços"
    }, [])
    const mainRef = useRef<HTMLDivElement>(null)

    const [headerFixed, setHeaderFixed] = useState(false)
    const [isVisible, setIsVisible] = useState(false)

    const [formData, setFormData] = useState({
        nomeEmpresa: "",
        cnpj: "",
        email: "",
        telefone: "",
        ramoAtividade: "",
        outrasInformacoes: ""
    })

    const navigate = useNavigate()

    const validate = () => {
        let newErrors: any = {}
        if (!formData.nomeEmpresa) {
            toast.error("Nome da Empresa é obrigatório", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }
        if (!formData.cnpj) {
            toast.error("CNPJ é obrigatório", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }
        if (!formData.email) {
            toast.error("Email é obrigatório", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }
        if (!formData.telefone) {
            toast.error("Telefone é obrigatório", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }
        return true
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault()

        const validationErrors = validate()
        if (!validationErrors) {
            return
        }
        const stringToSend =
            formData.nomeEmpresa +
            ";" +
            formData.cnpj +
            ";" +
            formData.email +
            ";" +
            formData.telefone +
            ";" +
            formData.ramoAtividade +
            ";" +
            formData.outrasInformacoes
        await EmailService.sendEmail(
            "Contato de empresa",
            stringToSend,
            "cotacao",
            null
        )
            .then((response) => {
                toast.success("Email enviado com sucesso", {
                    style: {
                        backgroundColor: "#4CAF50",
                        color: "#fff",
                        padding: "16px",
                        borderRadius: "10px",
                        border: "2px solid #4CAF50",
                        fontSize: "16px"
                    }
                })
            })

            .catch((error) => {
                toast.error("Erro ao enviar email", {
                    style: {
                        backgroundColor: "#f44336",
                        color: "#fff",
                        padding: "16px",
                        borderRadius: "10px",
                        border: "2px solid #D32F2F",
                        fontSize: "16px"
                    }
                })
            })
    }

    const headerFixedTrue = () => {
        setHeaderFixed(true)
    }

    const headerFixedFalse = () => {
        setHeaderFixed(false)
    }

    const scrollToTop = () => {
        if (mainRef.current) {
            mainRef.current.scrollTo({ top: 0, behavior: "smooth" })
        }
    }

    return (
        <>
            <Helmet>
                <title>Nossos Serviços</title>
                <meta
                    name="description"
                    content="Oferecemos soluções logísticas sob medida para sua operação. Fulfillment, B2B, B2C com inteligência e tecnologia."
                />
                <meta
                    name="keywords"
                    content="logística, fulfillment, B2C, B2B, transporte, soluções logísticas, entregas, e-commerce"
                />
                <meta name="author" content="LogPlace" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <meta property="og:title" content="Nossos Serviços" />
                <meta
                    property="og:description"
                    content="Somos líderes em soluções logísticas para o pequeno, médio e-commerce e grandes operações de varejo."
                />
                <meta property="og:url" content="https://logplace.com.br" />
                <meta property="og:type" content="website" />
            </Helmet>
            <ScrollToTopButton show={isVisible} scrollPage={scrollToTop} />
            <div
                ref={mainRef}
                className="relative flex flex-col flex-1 w-full h-screen overflow-auto bg-white xl:overflow-y-auto"
                onScroll={(event: any) => {
                    if (event.currentTarget.scrollTop > 10) {
                        setHeaderFixed(true)
                    } else {
                        setHeaderFixed(false)
                    }

                    if (event.currentTarget.scrollTop > 500) {
                        setIsVisible(true)
                    } else {
                        setIsVisible(false)
                    }
                }}
            >
                <div className="absolute inset-0 w-full bg-gradient-to-b from-white from-5% via-secondary-1 via-30% via-secondary-2 via-35% to-primary to-80% z-0">
                    <ToastContainer
                        position="bottom-left"
                        autoClose={1500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        icon={false}
                        limit={1}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Header
                        background={headerFixed ? " " : ""}
                        headerFixedTrue={headerFixedTrue}
                        headerFixedFalse={headerFixedFalse}
                    />
                    {/*<img
                        className="opacity-25 object-cover hidden lg:flex absolute top-0 left-0 right-0 bottom-0  h-[100%] w-[100%] max-h-[48rem]"
                        src={t1}
                        alt="entregador"
                    />*/}
                    <div className="h-[28rem] justify-center items-center flex flex-col 2xl:px-[32rem] space-y-10">
                        <h1 className="z-20 text-2xl font-bold text-center text-white md:text-3xl xl:text-5xl">
                            Somos a solução logística ideal para a sua operação!
                        </h1>
                        {/*<p className="z-20 text-xl text-center text-white xl:text-2xl">
                            Oferecemos uma operação bem estruturada de ponta a
                            ponta, com segurança e resultados garantidos!
                        </p>*/}
                    </div>
                    <div className="h-[50rem] lg:h-[30rem] xl:h-[32rem] flex flex-col space-y-6 lg:space-y-10 bg-white justify-center items-center lg:space-x-5 lg:px-44">
                        <h1 className="mt-10 text-xl font-bold text-center xl:text-4xl">
                            Conte com a nossa operação inteligente para otimizar
                            seus resultados.
                        </h1>
                        <div className="space-y-4 2xl:px-44">
                            <p className="text-xl text-center xl:text-2xl">
                                A LogPlace oferece soluções de entrega B2B e B2C
                                em todo o Brasil. Com uma vasta experiência e
                                uma equipe altamente especializada, nos
                                destacamos por nossa confiabilidade, eficiência
                                e compromisso com a satisfação do cliente.
                            </p>
                            <p className="text-xl text-center xl:text-2xl">
                                A LogPlace, líder incontestável em logística no
                                Nordeste, expande continuamente seus horizontes,
                                oferecendo soluções de entrega B2B e B2C em todo
                                o Nordeste.
                            </p>
                            <p className="text-xl text-center xl:text-2xl">
                                Com mais de 18 anos de experiência no mercado, a
                                LogPlace se destaca como o principal operador de
                                entrega B2C da região, proporcionando serviços
                                excepcionais que vão desde operações de lotação
                                até entregas expressas.
                            </p>
                        </div>
                    </div>
                    <div className="h-[20rem] lg:h-[18rem] xl:h-[18rem] flex flex-col space-y-4 lg:space-y-10 bg-white justify-center items-center lg:px-44">
                        <div className="w-full h-1 border-t-[1px] border-red-600"></div>
                        <h1 className="text-lg font-bold text-center xl:text-2xl">
                            Temos relações comerciais de longa data com grandes
                            empresas e milhares de motoristas.
                        </h1>
                        <div className="w-full h-1 border-t-[1px] border-red-600"></div>
                    </div>
                    <div className="h-[15rem] lg:h-[20rem] xl:h-[28rem] flex flex-col space-y-4 lg:space-y-10 bg-white justify-center items-center lg:px-44">
                        <img
                            className="h-[100%] w-[100%] object-contain"
                            src={banner}
                            alt="entregador"
                        />
                    </div>
                    <div className="h-[15rem] lg:h-[20rem] xl:h-[28rem] flex flex-col space-y-4 lg:space-y-10 bg-white justify-center items-center lg:px-44">
                        <img
                            className="h-[100%] w-[100%] object-contain"
                            src={banner2}
                            alt="entregador"
                        />
                    </div>
                    <div className="h-[75rem] lg:h-[68rem] xl:h-[38rem] flex flex-col-reverse xl:flex-row   bg-white lg:px-44 lg:py-8 xl:space-x-4">
                        <div className="flex w-full h-full p-2 xl:w-1/2 xl:p-0">
                            <div className="flex flex-col w-full h-full p-4 rounded-lg shadow-md bg-primary">
                                <div className="flex items-center justify-center flex-1">
                                    <h1 className="text-2xl font-bold text-white">
                                        Comece a operar conosco agora!
                                    </h1>
                                </div>
                                <form
                                    onSubmit={handleSubmit}
                                    className="flex flex-col justify-center w-full space-y-2 flex-5"
                                >
                                    <div className="flex flex-row w-full h-12 space-x-2">
                                        <input
                                            className="w-1/2 p-2 rounded-md"
                                            type="text"
                                            placeholder="Nome da Empresa"
                                            name="nomeEmpresa"
                                            value={formData.nomeEmpresa}
                                            onChange={handleChange}
                                        />
                                        <input
                                            className="w-1/2 p-2 rounded-md"
                                            type="text"
                                            placeholder="CNPJ"
                                            name="cnpj"
                                            value={formData.cnpj}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="flex flex-row w-full h-12 space-x-2">
                                        <input
                                            className="w-1/2 p-2 rounded-md"
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                        <input
                                            className="w-1/2 p-2 rounded-md"
                                            type="tel"
                                            placeholder="DDD-Numero (Whatsapp)"
                                            name="telefone"
                                            value={formData.telefone}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="flex flex-row w-full h-12 space-x-2">
                                        <input
                                            className="w-full p-2 rounded-md"
                                            type="text"
                                            placeholder="Ramo de atividade da empresa"
                                            name="ramoAtividade"
                                            value={formData.ramoAtividade}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="flex flex-row w-full h-24 space-x-2">
                                        <textarea
                                            className="w-full p-2 rounded-md resize-none"
                                            placeholder="Outras informações"
                                            name="outrasInformacoes"
                                            value={formData.outrasInformacoes}
                                            onChange={handleChange}
                                        />
                                    </div>

                                    <div className="w-full h-12 space-x-2">
                                        <button
                                            className="w-full h-full rounded-lg bg-secondary-1"
                                            type="submit"
                                        >
                                            <h1 className="font-semibold text-white">
                                                QUERO OPERAR COM A LOGPLACE
                                            </h1>
                                        </button>
                                    </div>

                                    <div className="w-full h-12 space-x-2">
                                        <p className="text-xs text-center text-white">
                                            Conforme estabelecido na Lei LGPD
                                            3218/2019, informamos que ao enviar
                                            os seus dados pessoais você autoriza
                                            a utilização dos dados fornecidos
                                            para utilização em processos
                                            internos.
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="flex flex-col items-center w-full h-full p-2 space-y-4 text-justify xl:w-1/2 xl:p-0">
                            <h1 className="font-semibold">
                                Você está pronto para levar sua operação
                                logística para o próximo nível?
                            </h1>
                            <p>
                                Na LogPlace, estamos totalmente equipados e
                                preparados para atender às suas necessidades
                                logísticas mais exigentes. Seja uma operação de
                                alto valor no Nordeste ou o transporte de
                                carretas Still Tech, estamos aqui para oferecer
                                soluções personalizadas e seguras que
                                impulsionarão o sucesso do seu negócio.
                            </p>
                            <p>
                                Nossa vasta experiência de mais de 18 anos no
                                mercado, combinada com uma equipe altamente
                                especializada e uma frota de mais de 900
                                veículos próprios, nos coloca na vanguarda da
                                indústria de logística. E agora, estamos prontos
                                para tornar você o próximo cliente satisfeito da
                                LogPlace.
                            </p>
                            <p>
                                Preencha o formulário ao lado e descubra como
                                podemos ajudar a otimizar sua cadeia de
                                suprimentos, garantindo entregas seguras,
                                eficientes e pontuais em todo o Brasil. Não
                                perca a oportunidade de fazer parceria com a
                                LogPlace – seu sucesso logístico começa aqui!
                            </p>
                            <p>
                                Junte-se à família LogPlace hoje e experimente a
                                diferença que uma logística de classe mundial
                                pode fazer para o seu negócio.
                            </p>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}
