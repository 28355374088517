import axios, { AxiosRequestConfig } from "axios"
const EMN: any = process.env.REACT_APP_BASE_URL_EMAIL
const EmailService = {
    sendEmail: async (
        subject: string,
        body: string,
        type: string,
        file: File | null
    ) => {
        const formData = new FormData()
        formData.append(
            "data",
            new Blob(
                [
                    JSON.stringify({
                        subject,
                        body,
                        type,
                        fileType: file ? file.type : " ",
                        fileName: file ? file.name : " "
                    })
                ],
                { type: "application/json" }
            )
        )
        if (file) formData.append("file", file)

        const configs: AxiosRequestConfig = {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }

        return axios.post(EMN, formData, configs)
    }
}

export default EmailService
