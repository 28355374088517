import { useEffect, useState } from "react"
import { CiMenuBurger } from "react-icons/ci"
import { useNavigate } from "react-router-dom"
import logoHeader from "../../assets/logoHeader.png"

interface HeaderProps {
    background?: string
    headerFixedTrue?: () => void
    headerFixedFalse?: () => void
}

export default function Header({
    background,
    headerFixedTrue,
    headerFixedFalse
}: HeaderProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    const [backgroundHeader, setBackgroundHeader] =
        useState<boolean>(!!background)

    const [showDetailsMenu, setShowDetailsMenu] = useState(false)

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    const navigate = useNavigate()

    const handleToNavigate = (value: String) => {
        navigate("/" + value)
    }

    useEffect(() => {
        setBackgroundHeader(!!background)
    }, [background])

    return (
        <>
            <div
                className={`w-[100vw] z-40 transition-all duration-500 h-[7rem] ${
                    backgroundHeader
                        ? "bg-white shadow-md fixed"
                        : " bg-transparent relative"
                }`}
            >
                <div className="z-10 flex flex-wrap items-center justify-between h-full bg-transparent xl:flex-nowrap 2xl:justify-around">
                    <button
                        onClick={() => {
                            handleToNavigate("home")
                        }}
                        className="flex items-end w-72 md:w-1/5 xl:justify-center "
                    >
                        <img
                            src={logoHeader}
                            className="w-[65%] h-[65%] md:w-[70%] md:h-[70%] xl:w-[80%] xl:h-[80%]"
                            alt="Logo"
                        />
                    </button>
                    <button
                        onClick={handleMenuToggle}
                        className="block p-2 mr-8 text-white xl:hidden md:mr-8"
                    >
                        {isMenuOpen ? (
                            <div className="flex items-center justify-center w-12 h-12 rotate-90 bg-white rounded-lg shadow-md sm:w-14 sm:h-14 md:w-16 md:h-16">
                                <CiMenuBurger color="#000" size={25} />
                            </div>
                        ) : (
                            <div className="flex items-center justify-center w-12 h-12 bg-white rounded-lg shadow-md sm:w-14 sm:h-14 md:w-16 md:h-16">
                                <CiMenuBurger color="#000" size={25} />
                            </div>
                        )}
                    </button>

                    <div
                        className={`hidden md:hidden xl:flex xl:justify-end xl:w-3/5 gap-4 space-x-2`}
                    >
                        <button
                            onClick={() => {
                                handleToNavigate("home")
                            }}
                            className="flex items-center justify-center w-32 h-12 rounded-full shadow-md sm:w-36 md:w-48 bg-primary sm:h-14"
                        >
                            <p className="text-xs font-bold text-white xl:text-sm">
                                Home
                            </p>
                        </button>
                        <button
                            onClick={() => {
                                handleToNavigate("aempresa")
                            }}
                            className="w-32 h-12 rounded-full shadow-md sm:w-36 md:w-48 bg-primary sm:h-14"
                        >
                            <p className="text-white text-xs md:text-[10px] xl:text-sm font-bold">
                                A Empresa
                            </p>
                        </button>
                        <button
                            onClick={() => {
                                handleToNavigate("servicos")
                            }}
                            className="w-32 h-12 rounded-full shadow-md sm:w-36 md:w-48 bg-primary sm:h-14"
                        >
                            <p className="text-white md:text-[0px] xl:text-sm font-bold">
                                Nossos Serviços
                            </p>
                        </button>
                        <div
                            onMouseEnter={() => setShowDetailsMenu(true)}
                            onMouseLeave={() => setShowDetailsMenu(false)}
                            className="relative"
                        >
                            <button
                                onClick={() => {
                                    handleToNavigate("servicos")
                                    setShowDetailsMenu(false)
                                }}
                                className="w-32 h-12 p-2 rounded-full shadow-md sm:w-36 md:w-48 bg-primary sm:h-14"
                            >
                                <p className="text-white md:text-[2px] xl:text-sm text-center font-bold">
                                    Trabalhe conosco
                                </p>
                            </button>
                            {showDetailsMenu && (
                                <div className="absolute z-30 w-56 p-2 bg-white rounded-lg shadow-lg">
                                    <button
                                        onClick={() =>
                                            handleToNavigate(
                                                "trabalheconosco/colaborador"
                                            )
                                        }
                                        className="block w-full px-4 py-2 text-sm text-center text-black rounded-lg hover:bg-gray-100"
                                    >
                                        Seja nosso Colaborador
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleToNavigate(
                                                "trabalheconosco/agregado"
                                            )
                                        }
                                        className="block w-full px-4 py-2 text-sm text-center text-black rounded-lg hover:bg-gray-100"
                                    >
                                        Seja nosso Agregado
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className={`hidden md:hidden xl:flex xl:justify-center md:w-1/5 mr-2 p-4`}
                    >
                        <button
                            onClick={() => {
                                handleToNavigate("rastreamento"),
                                    setShowDetailsMenu(false)
                            }}
                            onMouseEnter={() => {
                                setShowDetailsMenu(false)
                            }}
                            className="w-32 h-12 rounded-full shadow-md sm:w-56 md:w-60 bg-secondary-2 sm:h-14"
                        >
                            <h1 className="text-xs font-bold text-white sm:text-sm">
                                Rastreamento
                            </h1>
                        </button>
                    </div>
                    <div
                        className={`z-20 w-full p-2 xl:hidden bg-white ${isMenuOpen ? "" : "hidden"}`}
                    >
                        <div className="w-full h-full p-4 space-y-4  md:space-y-0 md:flex md:flex-row md:justify-between">
                            <button
                                onClick={() => handleToNavigate("home")}
                                className="text-xl font-bold md:items-start md:flex"
                            >
                                Home
                            </button>
                            <div>
                                <button
                                    onClick={() => handleToNavigate("aempresa")}
                                    className="text-xl font-bold"
                                >
                                    A Empresa
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => handleToNavigate("servicos")}
                                    className="text-xl font-bold"
                                >
                                    Nossos Serviços
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => handleToNavigate("servicos")}
                                    className="text-xl font-bold"
                                >
                                    Trabalhe conosco
                                </button>
                                <div className="flex flex-col items-start mt-2 ml-4">
                                    <button
                                        onClick={() =>
                                            handleToNavigate(
                                                "trabalheconosco/colaborador"
                                            )
                                        }
                                        className=""
                                    >
                                        Seja nosso Colaborador
                                    </button>
                                    <button
                                        onClick={() =>
                                            handleToNavigate(
                                                "trabalheconosco/agregado"
                                            )
                                        }
                                        className=""
                                    >
                                        Seja nosso Agregado
                                    </button>
                                </div>
                            </div>
                            <div>
                                <button
                                    onClick={() =>
                                        handleToNavigate("rastreamento")
                                    }
                                    className="text-xl font-bold"
                                >
                                    Rastreamento
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
