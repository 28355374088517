import { FaChevronUp } from "react-icons/fa"

interface ScrollToTopButtonProps {
    show: boolean
    scrollPage: () => void
}

const ScrollToTopButton = ({ show, scrollPage }: ScrollToTopButtonProps) => {
    return (
        <div className="fixed z-40 h-10 w-10 md:h-20 md:w-20 bottom-10 right-10">
            {show && (
                <button
                    onClick={scrollPage}
                    className=" bg-primary rounded-full h-14 w-14 md:h-20 md:w-20 justify-center items-center flex shadow shadow-white"
                >
                    <FaChevronUp className="text-white text-xl md:text-3xl" />
                </button>
            )}
        </div>
    )
}

export default ScrollToTopButton
