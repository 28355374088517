import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { AiOutlineLike } from "react-icons/ai"
import { MdOutlineAutoGraph } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import Footer from "../../components/footer/Footer"
import Header from "../../components/header/Header"
import EmailService from "../../services/EmailService"

import { FaRegHandshake } from "react-icons/fa6"
import { HiOutlineLightBulb } from "react-icons/hi"

export default function CollaboratorScreen() {
    useEffect(() => {
        document.title = "Logplace - Nossos Serviços"
    }, [])

    const [headerFixed, setHeaderFixed] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [formData, setFormData] = useState<{
        name: string
        email: string
        phone: string
        linkedin: string
        info: string
        resume: File | null
    }>({
        name: "",
        email: "",
        phone: "",
        linkedin: "",
        info: "",
        resume: null
    })
    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phone: "",
        linkedin: "",
        resume: ""
    })

    const navigate = useNavigate()

    const handleClick = () => {
        navigate("/rastreamento")
    }

    const headerFixedTrue = () => {
        setHeaderFixed(true)
    }

    const headerFixedFalse = () => {
        setHeaderFixed(false)
    }

    const validateForm = () => {
        const newErrors = {
            name: "",
            email: "",
            phone: "",
            linkedin: "",
            resume: ""
        }
        let isValid = true

        if (!formData.name) {
            toast.error("Nome é obrigatório", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!formData.email || !emailRegex.test(formData.email)) {
            toast.error("Email inválido", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }

        const phoneRegex = /^\d{2}\d{8,9}$/
        if (!formData.phone || !phoneRegex.test(formData.phone)) {
            toast.error("Telefone inválido (ex: 11999999999)", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }

        if (!formData.linkedin) {
            toast.error("Perfil do LinkedIn é obrigatório", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }

        if (!formData.resume) {
            newErrors.resume = "Currículo é obrigatório"
            toast.error("Currículo é obrigatório", {
                style: {
                    backgroundColor: "#f44336",
                    color: "#fff",
                    padding: "16px",
                    borderRadius: "10px",
                    border: "2px solid #D32F2F",
                    fontSize: "16px"
                }
            })
            return false
        }

        setErrors(newErrors)
        return isValid
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (validateForm()) {
            try {
                const response = await EmailService.sendEmail(
                    "Quero ser colaborador",
                    formData.name +
                        ";" +
                        formData.email +
                        ";" +
                        formData.phone +
                        ";" +
                        formData.linkedin +
                        ";" +
                        formData.info,
                    "colaborador",
                    formData.resume as File
                )

                if (response.status === 200) {
                    toast.success("Dados enviados com sucesso", {
                        style: {
                            backgroundColor: "#4CAF50",
                            color: "#fff",
                            padding: "16px",
                            borderRadius: "10px",
                            border: "2px solid #4CAF50",
                            fontSize: "16px"
                        }
                    })
                } else {
                    toast.error("Erro ao enviar os dados", {
                        style: {
                            backgroundColor: "#f44336",
                            color: "#fff",
                            padding: "16px",
                            borderRadius: "10px",
                            border: "2px solid #D32F2F",
                            fontSize: "16px"
                        }
                    })
                }
            } catch (error) {
                toast.error("Erro ao enviar os dados", {
                    style: {
                        backgroundColor: "#f44336",
                        color: "#fff",
                        padding: "16px",
                        borderRadius: "10px",
                        border: "2px solid #D32F2F",
                        fontSize: "16px"
                    }
                })
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Trabalhe Conosco</title>
                <title>Trabalhe Conosco</title>
                <meta
                    name="description"
                    content="Oferecemos soluções logísticas sob medida para sua operação. Fulfillment, B2B, B2C com inteligência e tecnologia."
                />
                <meta
                    name="keywords"
                    content="logística, fulfillment, B2C, B2B, transporte, soluções logísticas, entregas, e-commerce"
                />
                <meta name="author" content="LogPlace" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <meta property="og:title" content="Nossos Serviços" />
                <meta
                    property="og:description"
                    content="Somos líderes em soluções logísticas para o pequeno, médio e-commerce e grandes operações de varejo."
                />
                <meta property="og:url" content="https://logplace.com.br" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div
                className="relative flex flex-col flex-1 w-full h-screen xl:overflow-y-auto"
                onScroll={(event: any) => {
                    if (event.currentTarget.scrollTop > 10) {
                        setHeaderFixed(true)
                    } else {
                        setHeaderFixed(false)
                    }

                    if (event.currentTarget.scrollTop > 500) {
                        setIsVisible(true)
                    } else {
                        setIsVisible(false)
                    }
                }}
            >
                <div className="inset-0 w-full h-full bg-gradient-to-b from-white via-secondary-1 via-70% via-secondary-2 via-20% to-primary to-80% z-0">
                    <ToastContainer
                        position="bottom-right"
                        autoClose={1500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        icon={false}
                        limit={1}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Header
                        background={headerFixed ? " " : ""}
                        headerFixedTrue={headerFixedTrue}
                        headerFixedFalse={headerFixedFalse}
                    />
                    <div className="h-[40rem] md:h-[40rem] xl:h-[30rem] justify-center items-center flex flex-col sm:px-[10rem] md:px-[13rem] xl:px-[25rem] 2xl:px-[29rem] ">
                        <h1 className="text-6xl font-bold text-center text-black">
                            Nós somos a LogPlace
                        </h1>
                        <p className="mt-12">
                            <p className="text-xl font-semibold text-center text-black">
                                Somos um empresa com mais de 18 anos de mercado
                                especializados em logística na região Nordeste
                                do Brasil, e acreditamos que o elo mais
                                importante do nosso sucesso está em nosso time
                                de colaboradores
                            </p>
                            <h1 className="mt-5 text-xl font-semibold text-center text-black">
                                Através de nossas mais de 11 unidades, buscamos
                                sempre a valorização dos nossos funcionários, o
                                fortalecimento da cultura e o fomento de novas
                                ideias buscando criar um ambiente positivo para
                                todos
                            </h1>
                        </p>
                    </div>
                    <div className="h-[120rem] sm:h-[50rem] md:h-[75rem] lg:h-[50rem] xl:h-[40rem] flex justify-center items-center">
                        <div className="flex flex-col items-center justify-center h-full p-4 mt-4 space-y-4 lg:w-3/4 xl:w-4/5 2xl:w-4/5 ">
                            <div className="flex items-center justify-center">
                                <h1 className="text-4xl font-semibold text-center text-primary">
                                    O que nos torna diferentes?
                                </h1>
                            </div>
                            <div className="flex flex-col justify-between w-full h-full p-4 lg:flex-row md:w-2/3 lg:w-full bg-primary lg:space-x-4 rounded-3xl">
                                <div className="flex flex-col h-full lg:w-1/4 ">
                                    <div className="flex items-center justify-center h-1/4">
                                        <AiOutlineLike
                                            color="white"
                                            size={80}
                                        />
                                    </div>
                                    <div className="flex flex-col p-2 text-center bg-white h-3/4 rounded-3xl justify-evenly">
                                        <div className="flex items-center justify-center h-1/4 xl:h-2/4">
                                            <h1 className="text-lg font-bold text-primary">
                                                Ambiente Colaborativo e
                                                Inclusivo
                                            </h1>
                                        </div>
                                        <div className="h-3/5 xl:h-2/4">
                                            <p>
                                                Aqui promovemos uma cultura de
                                                trabalho em equipe, onde cada
                                                colaborador é valorizado e
                                                incentivado a criar e
                                                compartilhar ideias.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="h-full lg:w-1/4">
                                    <div className="flex items-center justify-center h-1/4">
                                        <MdOutlineAutoGraph
                                            color="white"
                                            size={80}
                                        />
                                    </div>
                                    <div className="flex flex-col p-2 text-center bg-white h-3/4 rounded-3xl justify-evenly">
                                        <div className="flex items-center justify-center h-1/4 xl:h-2/4">
                                            <h1 className="text-lg font-bold text-primary">
                                                Oportunidades de Crescimento e
                                                Desenvolvimento
                                            </h1>
                                        </div>
                                        <div className="h-3/5 xl:h-2/4">
                                            <p>
                                                Oferecemos amplas oportunidades
                                                de desenvolvimento profissional,
                                                com treinamentos regulares,
                                                workshops e planos de carreira
                                                bem definidos.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="h-full lg:w-1/4">
                                    <div className="flex items-center justify-center h-1/4">
                                        <HiOutlineLightBulb
                                            color="white"
                                            size={80}
                                        />
                                    </div>
                                    <div className="flex flex-col p-2 text-center bg-white h-3/4 rounded-3xl justify-evenly">
                                        <div className="flex items-center justify-center h-1/4 xl:h-2/4">
                                            <h1 className="text-lg font-bold text-primary">
                                                Inovação Tecnológica e
                                                Sustentabilidade
                                            </h1>
                                        </div>
                                        <div className="h-3/5 xl:h-2/4">
                                            <p>
                                                Temos um forte compromisso com
                                                práticas sustentáveis,
                                                contribuindo para um futuro mais
                                                verde enquanto melhoramos nossa
                                                eficiência operacional
                                                utilizando as tecnologias mais
                                                recentes do mercado.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="h-full lg:w-1/4">
                                    <div className="flex items-center justify-center h-1/4">
                                        <FaRegHandshake
                                            color="white"
                                            size={80}
                                        />
                                    </div>
                                    <div className="flex flex-col p-2 text-center bg-white h-3/4 rounded-3xl justify-evenly">
                                        <div className="flex items-center justify-center h-1/4 xl:h-2/4">
                                            <h1 className="text-lg font-bold text-primary">
                                                Pacote de Benefícios Competitivo
                                            </h1>
                                        </div>
                                        <div className="h-3/5 xl:h-2/4">
                                            <p>
                                                Valorizamos nossos colaboradores
                                                com um pacote de benefícios
                                                atrativo, além de salários
                                                alinhados com a competitividade
                                                do mercado.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h-[44rem] sm:h-[50rem] md:h-[75rem] lg:h-[50rem] xl:h-[43rem] flex justify-center items-center">
                        <div className="flex flex-row items-center justify-center w-full h-full p-4">
                            <div className="flex flex-col w-full h-full p-4 rounded-lg shadow-sm shadow-gray-100 lg:w-2/3 2xl:w-1/2 bg-primary">
                                <div className="flex items-center justify-center p-4 mb-4">
                                    <h1 className="text-2xl font-bold tracking-wide text-center text-white">
                                        Faça parte do nosso sucesso!
                                    </h1>
                                </div>
                                <form
                                    className="flex flex-col justify-center space-y-4"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="flex flex-row h-12 ">
                                        <input
                                            className="w-full px-4 py-2 rounded-md"
                                            placeholder="Nome Completo"
                                            value={formData.name}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    name: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-row h-12 space-x-4">
                                        <input
                                            className="w-1/2 px-4 py-2 rounded-md"
                                            placeholder="Email"
                                            value={formData.email}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    email: e.target.value
                                                })
                                            }
                                        />

                                        <input
                                            className="w-1/2 px-4 py-2 rounded-md"
                                            placeholder="DDD+Numero (Whatsapp)"
                                            value={formData.phone}
                                            type="tel"
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    phone: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-row h-12 ">
                                        <input
                                            className="w-full px-4 py-2 rounded-md"
                                            placeholder="Perfil do Linkedin (link)"
                                            value={formData.linkedin}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    linkedin: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-row h-24">
                                        <textarea
                                            className="w-full p-2 rounded-md resize-none"
                                            placeholder="Outras informações"
                                            value={formData.info}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    info: e.target.value
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-row h-12">
                                        <input
                                            className="w-full h-full text-white file:rounded-lg file:border-none file:px-4 file:py-2 file:font-semibold file:bg-white"
                                            type="file"
                                            placeholder="Anexe seu currículo"
                                            accept=".pdf,.doc,.docx"
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    resume: e.target.files
                                                        ? e.target.files[0]
                                                        : null
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="w-full h-12">
                                        <button
                                            className="w-full h-full bg-green-400 rounded-lg"
                                            type="submit"
                                        >
                                            <h1 className="font-semibold text-white">
                                                QUERO TRABALHAR COM A LOGPLACE
                                            </h1>
                                        </button>
                                    </div>
                                    <div className="w-full h-12 p-4 space-x-2">
                                        <span className="text-center text-white">
                                            Conforme estabelecido na Lei LGPD
                                            3218/2019, informamos que ao enviar
                                            os seus dados pessoais você autoriza
                                            a utilização dos dados fornecidos
                                            para utilização em processos
                                            internos.
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    )
}
